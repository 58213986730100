
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































.relative {
  position: relative;
}

.solutions-container {
  // Create a new stacking context to prevent solutions lines to appear behind background
  position: relative;
  z-index: 1;
  overflow: hidden;

  @include mq($from: m) {
    margin-top: -5rem;
  }
}

.solutions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 8rem;
  overflow: hidden;

  @include mq($from: s) {
    margin-bottom: 0;
  }

  .reset {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: baseline;
  }

  li {
    position: relative;
    width: 100%;

    ::v-deep {
      .solution-title {
        position: unset;
        left: unset;
        top: unset;
        width: unset;
      }
    }
  }

  li:not(:last-of-type) {
    margin-bottom: clamp(2rem, 4.761vw, 8rem);
  }
}

li.solution-title {
  opacity: 0;
  transform: translateX(100%);

  @media (prefers-reduced-motion) {
    opacity: 1;
    transform: translateX(0);
  }
}

.visual-container {
  opacity: 0;
  transform: translateY(100px);

  @media (prefers-reduced-motion) {
    opacity: 1;
    transform: translateY(0);
  }
}
